<template>
  <div>
    <div class="row mb-3">
      <div class="col">
        <h4>
{{ $t("countries.countries") }}
</h4>
      </div>
      <div class="col-6 col-md-4">
        <input
            type="search"
            class="form-control py-0"
            v-model="filter"
            :placeholder="`Search countries..`"
        />
      </div>
    </div>
    <div class="row">
      <div
          v-for="country in filterCountries"
          class="col-12 col-md-6 col-xl-4"
          :key="country.id"
      >
        <label class="cstm-check dropdown-item">
              <span class="text-black mx-2 regular-14"
              >{{ country.name }} ({{ country.code }})</span
              >
          <input
              type="checkbox"
              v-model="country.enabled"
              v-on:change="
                  changeOption(
                    'countries',
                    country.id,
                    $event.currentTarget.checked,
                    'id'
                  )
                "
          />
          <span class="checkmark select mx-2"></span>
        </label>
      </div>
    </div>
  </div>
</template>

<script>
import http from "@/modules/http";

export default {
  name: "Countries",
  data() {
    return {
      countries: [],
      filter: "",
    };
  },
  computed: {
    filterCountries() {
      if (this.filter.length < 1) {
        return this.countries;
      }
      if (this.filter === "eu") {
        return this.countries.filter((e) => e.is_eu);
      }
      if (this.filter === "un") {
        return this.countries.filter((e) => e.is_un);
      }
      return this.countries.filter(
          (e) => e.name.toLowerCase().indexOf(this.filter) > -1
      );
    },
  },
  mounted() {
    http.fetch("/countries/all").then((data) => {
      this.countries = data;
    });
  },
  methods: {
    changeOption(type, id, enable, row = "code") {
      const method = enable ? "enable" : "disable";
      let data = {};
      data[row] = id;
      http.fetch(`/${type}/${method}`, data, true);
    },
  },
};
</script>
