<template>
  <div class="mt-4 mx-3 row white-box px-3 py-4">
    <div class="white-box-top"></div>
    <div class="col-md-4 col-lg-2">
      <nav class="left-nav">
        <button
          class="btn w-100 text-start"
          v-on:click="active = 'webshops'"
          :class="active === 'webshops' ? 'btn-primary' : 'btn-simple'"
        >
          {{ $t("countries.countries") }}
        </button>
        <button
          class="btn w-100 text-start"
          v-on:click="active = 'deliveries'"
          :class="active === 'deliveries' ? 'btn-primary' : 'btn-simple'"
        >
          {{ $t("countries.deliveries") }}
        </button>
        <button
          class="btn w-100 text-start"
          v-on:click="active = 'payments'"
          :class="active === 'payments' ? 'btn-primary' : 'btn-simple'"
        >
          Payments
        </button>
        <button
          class="btn w-100 text-start"
          v-on:click="active = 'vat'"
          :class="active === 'vat' ? 'btn-primary' : 'btn-simple'"
        >
          {{ $t("countries.vatRates") }}
        </button>
        <button
          class="btn w-100 text-start"
          v-on:click="active = 'countries'"
          :class="active === 'countries' ? 'btn-primary' : 'btn-simple'"
        >
          {{ $t("countries.addCountry") }}
        </button>
        <button
          class="btn w-100 text-start"
          v-on:click="active = 'currencies'"
          :class="active === 'currencies' ? 'btn-primary' : 'btn-simple'"
        >
          {{ $t("countries.currencies") }}
        </button>
        <button
          class="btn w-100 text-start"
          v-on:click="active = 'languages'"
          :class="active === 'languages' ? 'btn-primary' : 'btn-simple'"
        >
          {{ $t("countries.languages") }}
        </button>
      </nav>
    </div>
    <div class="col-md-8 col-lg-10">
      <h2>
{{ $t("countries.countries") }}
</h2>
      <CountriesSetting v-if="active === 'countries'" />
      <Deliveries v-if="active === 'deliveries'" />
      <Payments v-if="active === 'payments'" />
      <VatRates v-if="active === 'vat'" />
      <DefaultWebshops v-if="active === 'webshops'" />
      <Languages v-if="active === 'languages'" />
      <Currencies v-if="active === 'currencies'" />
    </div>
  </div>
</template>

<script>
import CountriesSetting from "@/components/settings/countries/Countries";
import Deliveries from "@/components/settings/countries/Deliveries";
import VatRates from "@/components/settings/countries/VatRates";
import DefaultWebshops from "@/components/settings/countries/DefaultWebshops";
import Languages from "./countries/Languages";
import Currencies from "./countries/Currencies";
import Payments from "./countries/Payments.vue";

export default {
  name: "Countries",
  components: {
    Payments,
    Currencies,
    Languages,
    DefaultWebshops,
    VatRates,
    Deliveries,
    CountriesSetting,
  },
  data() {
    return {
      active: "webshops",
    };
  },
  mounted() {
    this.load();
  },
  methods: {
    load() {},
  },
};
</script>
