<template>
  <div
    ref="modal"
    class="modal fade"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="staticBackdropLabel">
            {{ $t("countries.deliveries") }}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            :aria-label="$t(`confirmModal.close`)"
          ></button>
        </div>
        <div class="modal-body">
          <div class="row row-tabs">
            <div
              class="col-auto p-0 m-0"
              v-for="l in activeLanguages"
              :key="l.code"
            >
              <button
                class="btn btn-tab"
                :class="{ active: l.code === selectedLanguage }"
                v-on:click="this.selectedLanguage = l.code"
              >
                <span class="semibold-14">{{ l.name }}</span>
              </button>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-4">
              <button
                v-for="delivery in deliveries"
                :key="delivery.id"
                class="btn w-100 d-block text-start"
                v-on:click="loadEditDelivery(delivery)"
                :class="{
                  'btn-primary': editDelivery?.id === delivery.id,
                  'btn-simple': editDelivery?.id !== delivery.id,
                }"
              >
                {{ delivery.name }}
              </button>
            </div>
            <div class="col-8 row" v-if="editDelivery !== null">
              <div class="col-12 mb-3 whitebg-input">
                <label class="form-label bold-12 text-gray">
                  {{ $t("deliveriesModal.deliveryMethodSku") }}
                </label>
                <input class="form-control" v-model="editDelivery.sku" />
              </div>
              <div class="col-12 mb-3 whitebg-input">
                <label class="form-label bold-12 text-gray">
                  {{ $t("deliveriesModal.deliveryMethodName") }}
                </label>
                <input
                  class="form-control"
                  v-model="editDelivery.name[selectedLanguage]"
                />
              </div>
              <div class="col-12 mb-3 whitebg-input">
                <label class="form-label bold-12 text-gray">
                  {{ $t("deliveriesModal.deliveryMethodDescription") }}
                </label>
                <input
                  class="form-control"
                  v-model="editDelivery.description[selectedLanguage]"
                />
              </div>
              <div class="col-12 mb-3 whitebg-input">
                <label class="form-label bold-12 text-gray">
                  {{ $t("deliveriesModal.deliveryMethodName") }}
                </label>
                <input
                  class="form-control"
                  v-model="editDelivery.name[selectedLanguage]"
                />
              </div>
              <div class="col-12 mb-3 whitebg-input">
                <label class="form-label bold-12 text-gray">
                  {{ $t("deliveriesModal.deliveryMethodAfterCartText") }}
                </label>
                <input
                  class="form-control"
                  v-model="editDelivery.text_after_selected[selectedLanguage]"
                />
              </div>
              <div class="col-12 mb-3 whitebg-input">
                <label class="form-label bold-12 text-gray">
                  {{ $t("deliveriesModal.deliveryMethodFooterText") }}
                </label>
                <input
                  class="form-control"
                  v-model="editDelivery.footer_description[selectedLanguage]"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-primary"
            v-on:click="saveDelivery"
          >
            {{ $t("finance.save") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import http from "@/modules/http";
const bootstrap = require("bootstrap");

export default {
  name: "DeliveriesModal",
  props: {
    deliveries: Array,
  },
  emits: ["saveDelivery"],
  computed: {
    activeLanguages() {
      const store = useStore();
      return store.state.languages;
    },
  },
  data() {
    return {
      selectedLanguage: "en",
      editDelivery: null,
    };
  },
  methods: {
    saveDelivery() {
      http.fetch(
        "/deliveries/methods/" + this.editDelivery.id + "/translate",
        this.editDelivery
      );
      this.$emit("saveDelivery", this.editDelivery);
    },
    hideModal() {
      this.myModal.hide();
    },
    loadEditDelivery(delivery) {
      http.fetch("/deliveries/methods/" + delivery.id).then((data) => {
        this.editDelivery = data;
      });
    },
    showModal() {
      this.myModal = new bootstrap.Modal(this.$refs.modal, {
        backdrop: "static",
      });
      this.myModal.show();
      this.loadEditDelivery(this.deliveries[0]);
    },
  },
};
</script>
